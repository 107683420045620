<template>
  <div>
    <b-card
      no-body
      :class="{'open': isDeliveryVisible}"
      class="collapse-icon"
    >
      <b-card-header
        :class="{'collapsed': !isDeliveryVisible}"
        :aria-expanded="isDeliveryVisible ? 'true' : 'false'"
        aria-controls="delivery-collapse"
        role="tab"
        data-toggle="collapse"
        @click="updateDeliveryVisible(!isDeliveryVisible)"
      >
        <h4 class="d-inline-block mr-1 mb-0">
          {{ $t('delivery') }}
        </h4>
      </b-card-header>
      <b-collapse
        id="delivery-collapse"
        v-model="isDeliveryVisible"
        role="tabpanel"
        accordion
      >
        <hr class="divider m-0" />
        <b-card-body>
          <div>
            <b-row>
              <b-col
                v-for="delivery in options.delivery"
                :key="delivery.id"
                md="6"
                lg="6"
                cols="12"
              >
                <delivery-card-variant
                  :variant="delivery"
                  :selected-variant="selectedDelivery"
                  @select="selectDelivery"
                />
              </b-col>
            </b-row>
            <div v-if="selectedDelivery === 1 && isDeliveryActive">
              <b-form-group
                class="mt-2"
                :label="$t('deliveryCost')"
                label-for="delivery-cost"
              >
                <b-row class="d-flex align-items-center">
                  <b-col cols="2">
                    <b-form-input
                      id="delivery-cost"
                      v-model="delivery_cost"
                      type="number"
                    />
                  </b-col>
                  <b-col
                    cols="2"
                    class="p-0"
                  >
                    <span>
                      {{ currency | currencySymbol }}
                    </span>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-row>
                <b-col
                  cols="12"
                  md="5"
                >
                  <b-button
                    class="mt-2"
                    variant="primary"
                    :disabled="!delivery_cost"
                    @click="generateDeliveryInvoice"
                  >
                    {{ $t('generateDeliveryInvoice') }}
                  </b-button>
                </b-col>
              </b-row>
              <transactions-documents-list :documents="deliveryDocuments" />
              <transactions-documents-list
                :documents="deliveryDocuments"
                :is-receipt="true"
              />
              <div
                v-if="isDeliveryPaymentSent"
                class="d-flex align-items-center"
              >
                <b-avatar
                  size="32"
                  variant="light-success"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                  />
                </b-avatar>
                <h5 class="mb-0 ml-1">
                  {{ $t('deliveryPaymentSent') }}
                </h5>
              </div>
              <b-row v-if="isDeliveryInvoiceReady">
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-button
                    class="mt-2"
                    variant="primary"
                    :disabled="deliverySendingToBuyer"
                    @click="sendDeliveryInvoiceToBuyer"
                  >
                    {{ $t('sendInvoiceToBuyer') }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card-body>
        <deal-other-docs document-type="delivery" />
      </b-collapse>
    </b-card>
    <b-card
      no-body
      :class="{'open': isClearanceVisible}"
      class="collapse-icon"
    >
      <b-card-header
        :class="{'collapsed': !isClearanceVisible}"
        :aria-expanded="isClearanceVisible ? 'true' : 'false'"
        aria-controls="clearance-collapse"
        role="tab"
        data-toggle="collapse"
        @click="updateClearanceVisible(!isClearanceVisible)"
      >
        <h4 class="d-inline-block mr-1 mb-0">
          {{ $t('customs') }}
        </h4>
      </b-card-header>
      <b-collapse
        id="clearance-collapse"
        v-model="isClearanceVisible"
        role="tabpanel"
        accordion
      >
        <hr class="divider m-0" />
        <b-card-body>
          <div>
            <b-row>
              <b-col
                v-for="custom in options.customs"
                :key="custom.id"
                md="6"
                lg="6"
                cols="12"
              >
                <delivery-card-variant
                  :variant="custom"
                  :disabled="selectedDelivery === 2"
                  :selected-variant="selectedCustom"
                  @select="selectCustom"
                />
              </b-col>
            </b-row>
            <div v-if="selectedCustom === 1 && isCustomsActive">
              <b-form-group
                class="mt-2"
                :label="$t('clearanceCost')"
                label-for="custom-cost"
              >
                <b-row class="d-flex align-items-center">
                  <b-col cols="2">
                    <b-form-input
                      id="custom-cost"
                      v-model="custom_cost"
                      type="number"
                    />
                  </b-col>
                  <b-col
                    cols="2"
                    class="p-0"
                  >
                    <span>
                      {{ currency | currencySymbol }}
                    </span>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-row>
                <b-col
                  cols="12"
                  md="5"
                >
                  <b-button
                    class="mt-2"
                    variant="primary"
                    :disabled="!custom_cost"
                    @click="generateClearanceInvoice"
                  >
                    {{ $t('generateCustomsInvoice') }}
                  </b-button>
                </b-col>
              </b-row>
              <transactions-documents-list :documents="customsDocuments" />
              <transactions-documents-list
                :documents="customsDocuments"
                :is-receipt="true"
              />
              <div
                v-if="isCustomsPaymentSent"
                class="d-flex align-items-center"
              >
                <b-avatar
                  size="32"
                  variant="light-success"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                  />
                </b-avatar>
                <h5 class="mb-0 ml-1">
                  {{ $t('customsPaymentSent') }}
                </h5>
              </div>
              <b-row v-if="isCustomInvoiceReady">
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-button
                    class="mt-2"
                    variant="primary"
                    :disabled="customsSendingToBuyer"
                    @click="sendCustomsInvoiceToBuyer"
                  >
                    {{ $t('sendInvoiceToBuyer') }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card-body>
        <deal-other-docs document-type="customs" />
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import {
  BCardBody, BButton, BRow, BCol, BFormGroup, BFormInput, BAvatar, BCardHeader, BCollapse, BCard,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import TransactionsDocumentsList from '@/views/trading/trading-deals/transactions/TransactionsDocumentsList.vue';
import DeliveryCardVariant from '@/views/trading/trading-deals/delivery/DeliveryCardVariant.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import DealOtherDocs from '@/views/trading/trading-deals/DealOtherDocs.vue';

export default {
  components: {
    DealOtherDocs,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TransactionsDocumentsList,
    DeliveryCardVariant,
    BAvatar,
    BCardHeader,
    BCollapse,
    BCard,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    currency: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isDeliveryInvoiceReady: false,
      isCustomInvoiceReady: false,
      delivery_cost: null,
      custom_cost: null,
      options: {
        delivery: [
          { icon: 'ShoppingCartIcon', name: this.$t('buyerDelivers'), id: 2 },
          { icon: 'TruckIcon', name: this.$t('weArrangeDeliver'), id: 1 },
        ],
        customs: [
          { icon: 'BriefcaseIcon', name: this.$t('customsByBuyer'), id: 2 },
          { icon: 'GiftIcon', name: this.$t('weArrangeCustoms'), id: 1 },
        ],
      },
      selectedDelivery: null,
      selectedCustom: null,
      deliverySendingToBuyer: false,
      customsSendingToBuyer: false,
      isDeliveryVisible: true,
      isClearanceVisible: true,
    };
  },
  computed: {
    currentRoute() {
      const { id } = this.$route.params;
      return id || '';
    },
    isDeliveryActive() {
      const buyerDocumentsSigned = this.$store.state.deal.deal.uploaded_documents.buyer
          && Object.values(this.$store.state.deal.deal.uploaded_documents.buyer).some(value => !!value);
      const supplierDocumentsSigned = this.$store.state.deal.deal.uploaded_documents.supplier
          && Object.values(this.$store.state.deal.deal.uploaded_documents.supplier).some(value => !!value);
      return [1, 2, 3, 4].includes(this.dealCurrentStatus);
    },
    isCustomsActive() {
      const buyerDocumentsSigned = Object.values(this.$store.state.deal.deal.uploaded_documents.buyer).some(value => !!value);
      const supplierDocumentsSigned = Object.values(this.$store.state.deal.deal.uploaded_documents.supplier).some(value => !!value);
      return [1, 2, 3, 4, 5].includes(this.dealCurrentStatus);
    },
    allInvoices() {
      return this.$store.state.deal.deal.offer.legal_members.reduce((acc, member) => [...acc, ...member.invoices], []);
    },
    isDeliveryPaymentSent() {
      const [invoice] = this.deliveryDocuments;
      return invoice?.receipt_sent;
    },
    isCustomsPaymentSent() {
      const [invoice] = this.customsDocuments;
      return invoice?.receipt_sent;
    },
    deliveryDocuments() {
      return this.allInvoices?.filter(item => item.type === 'delivery') || [];
    },
    customsDocuments() {
      return this.allInvoices?.filter(item => item.type === 'clearance') || [];
    },
    dealCurrentStatus() {
      return this.$store.state.deal.deal.status;
    },
  },
  watch: {
    dealCurrentStatus: {
      immediate: true,
      handler() {
        this.selectedDelivery = this.$store.state.deal.deal.delivery_type;
        this.selectedCustom = this.$store.state.deal.deal.clearance_type;
      },
    },
    isDeliveryPaymentSent: {
      immediate: true,
      handler(value) {
        if (value) {
          setTimeout(() => this.updateDeliveryVisible(false), 500);
        }
      },
    },
    isCustomsPaymentSent: {
      immediate: true,
      handler(value) {
        if (value) {
          setTimeout(() => this.updateClearanceVisible(false), 500);
        }
      },
    },
  },
  created() {
    const { clearance_price, delivery_price } = this.$store.state.deal.deal;
    this.delivery_cost = delivery_price;
    this.custom_cost = clearance_price;
  },
  methods: {
    updateDeliveryVisible(val = true) {
      this.isDeliveryVisible = val;
    },
    updateClearanceVisible(val = true) {
      this.isClearanceVisible = val;
    },
    async selectDelivery(deliveryId) {
      this.selectedDelivery = deliveryId;
      await this.postVariant(this.selectedDelivery, 'delivery', 'delivery_type');
      if (this.selectedDelivery === 2) {
        await this.selectCustom(this.selectedDelivery);
      }
    },
    async selectCustom(customId) {
      this.selectedCustom = customId;
      await this.postVariant(customId, 'clearance', 'clearance_type');
    },
    async postVariant(id, type, paramName) {
      try {
        const params = new URLSearchParams();
        params.append(paramName, id);
        await this.$store.dispatch('deal/setDeliveryOptions', { payload: params, id: this.currentRoute, type });
      } catch (e) {
        console.log(e);
      }
    },
    async generateDeliveryInvoice() {
      try {
        const delivery = new URLSearchParams();
        delivery.append('delivery_price', this.delivery_cost);
        await this.$store.dispatch('deal/setPrice', { id: this.currentRoute, payload: delivery });
        this.isDeliveryInvoiceReady = true;
      } catch (e) {
        console.log(e);
      }
    },
    async generateClearanceInvoice() {
      try {
        const delivery = new URLSearchParams();
        delivery.append('clearance_price', this.custom_cost);
        await this.$store.dispatch('deal/setClearancePrice', { id: this.currentRoute, payload: delivery });
        this.isCustomInvoiceReady = true;
      } catch (e) {
        console.log(e);
      }
    },
    async sendDeliveryInvoiceToBuyer() {
      this.deliverySendingToBuyer = true;
      await this.sendInvoiceToBuyer();
      this.deliverySendingToBuyer = false;
    },
    async sendCustomsInvoiceToBuyer() {
      this.customsSendingToBuyer = true;
      await this.sendInvoiceToBuyer();
      this.customsSendingToBuyer = false;
    },
    async sendInvoiceToBuyer() {
      try {
        await this.$store.dispatch('deal/sendInvoiceToBuyer', this.currentRoute);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('invoiceSentSuccessfully'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        });
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('failedToSendInvoice'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    getAvatarText(data) {
      return avatarText(data);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
